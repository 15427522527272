import React from "react"
import Containers from "../components/container"
import Header from "../components/header"
import Headertop from "../components/headertop"
import Footer from "../components/footer"
import Formfooter from "../components/form-footer"
import { Container, Col, Row } from "react-bootstrap"
import * as aboutStyles from "../css/about.module.css"
import { Link } from "gatsby"
import { Icon } from '@iconify/react';
import chevronDoubleRight from '@iconify/icons-mdi-light/chevron-double-right'
import Bgabout from "../components/bgabout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

const SeoTitle = "เกี่ยวกับเรา"
const SeoDecription = "เรามีความภาคภูมิใจในการขับเคลื่อนด้วยพลังขับเคลื่อนที่มีผลวัต ผู้บริหารที่มีคุณภาพและมีทักษะสูงที่มีความรู้เชิงลึกในทุกแง่มุมของธุรกิจสิ่งทอ"
const SeoCanonical = "/about-us/"

export default function Aboutus() {
    return (
    <Containers>
      <Seo 
        title={SeoTitle}
        description={SeoDecription}
        pathname={SeoCanonical}
       />
      <Headertop />
      <Header />
      <Bgabout/>
      <div className={aboutStyles.herob}>
      <Container fluid="xl">
              <Row>
                  <Col><p><Link to="/">Home</Link>  <Icon icon={chevronDoubleRight} />  About</p></Col>
              </Row>
      </Container>
      </div>
      <div className={aboutStyles.herosection}>
          <Container fluid="xl">
              <Row>
                <Col md={12}>
                  <p>สิ่งสำคัญที่สุดคือ เรามีความภาคภูมิใจในการขับเคลื่อนด้วยพลังขับเคลื่อนที่มีผลวัต ผู้บริหารที่มีคุณภาพและมีทักษะสูงที่มีความรู้เชิงลึกในทุกแง่มุมของธุรกิจสิ่งทอ ทรงไทยเท็กซ็ไทล์ อุตสาหกรรมสิ่งทอ</p>
                  <p>ผู้ผลิตก่อตั้งขึ้นในปี พ.ศ. 2538 ตามรอยของบริษัทแม่ บริษัท เจริญรุ่งโรจน์สิ่งทอ จำกัด ซึ่งก่อตั้งขึ้นในปี พ.ศ. 2504 ด้วยประสบการณ์ที่กว้างขวางกว่า 60 ปีในด้านสิ่งทอ บริษัทมีทีมงานที่มีความสามารถสูงและผู้เชี่ยวชาญระดับมืออาชีพในทุกแผนกของการผลิต การพัฒนาผลิตภัณฑ์ การตลาดและการขายสินค้า เพื่อให้บริการลูกค้าได้อย่างรวดเร็วที่สุด</p>
                </Col>
                <Col md={12}>
                  <center>
                    <h3 style={{fontSize: '28px', color: '#222185'}}>“จากรุ่นสู่รุ่น”</h3>
                    <StaticImage src="../images/logo-stt.jpg" alt="Songthai-about-logo-01" />
                  </center>
                </Col>
              </Row>
          </Container>
      </div>
      <div className={aboutStyles.herosectionb}>
          <Container fluid="xl">
              <Row>
                <Col md={12}>
                    <h3 style={{fontSize: '28px', color: '#222185'}}>วิสัยทัศน์ของเรา</h3>
                    <p>ผลิตสินค้าเทียบเคียงมาตรฐานสากล มุ่งลูกค้าเป็นที่สำคัญ และแข่งขันได้ทั่วโลก ด้วยคุณภาพที่ดี ราคาถูก เร็ว เทคโนโลยีล่าสุด และต่อเนื่อง</p>
                </Col>
              </Row>
          </Container>
      </div>
      <div className={aboutStyles.herosection}>
          <Container fluid="xl">
              <Row>
                <Col md={12}>
                    <h3 style={{fontSize: '28px', color: '#222185'}}>ค่านิยมของเรา</h3>
                    <p><u><b>คุณภาพ:</b></u> เราส่งมอบคุณภาพที่ตกลงไว้ทุกครั้ง</p>
                    <p><u><b>ความสัมพันธ์:</b></u> ความสำเร็จของเราขึ้นอยู่กับความสัมพันธ์ที่ดีกับคู่ค้าและเพื่อนร่วมงานทั้งหมดของเรา เราเชื่อว่าความคาดหวังและความเข้าใจนั้นเป็นสิ่งที่จำเป็นสำหรับการสร้างมูลค่าที่แท้จริง</p>
                    <p><u><b>จุดสนใจ:</b></u> เราให้ความสำคัญกับการสร้างคุณค่าให้กับลูกค้าของเราและสำหรับกลุ่มโดยรวม</p>  
                    <p><u><b>วิธีการแก้ปัญหา:</b></u> วิศวกรรมอย่างต่อเนื่องเพื่อจัดหาวิธีการแก้ปัญหาสำหรับลูกค้าของเรา</p>
                </Col>
              </Row>
          </Container>
      </div>
      <div className={aboutStyles.herosectionb}>
          <Container fluid="xl">
              <Row>
                <Col md={12}>
                    <h3 style={{fontSize: '28px', color: '#222185'}}>ภารกิจของเรา</h3>
                    <p>เพื่อก้าวขึ้นเป็นผู้นำในอุตสาหกรรมสิ่งทอที่มีความรับผิดชอบ ซึ่งเข้าใจความต้องการของมนุษย์และดำเนินการเพื่อสร้างความสมดุลให้กับข้อกำหนดเหล่านี้โดยการผลิตผลิตภัณฑ์ระดับโลกโดยคำนึงถึงข้อกังวลทางสังคม เศรษฐกิจ และสิ่งแวดล้อมของประเทศ</p>
                </Col>
              </Row>
          </Container>
      </div>
      <Formfooter />
      <Footer />
    </Containers>
  );
}